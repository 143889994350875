import React from "react"
import Footer from "../components/footer"
import "../styles/join.less"
import SEO from "../components/SEO"
import { SolutionTopBanner } from "../components/block"
import { Button } from "../components/ui"
// import Drop from "../components/drop"
import { OurValue } from "./about-us"
const loadText = require("src/utils").loadText
const url = loadText("url")
const data = loadText("join-the-team")
import { lang } from "../utils"
const TopBlock = () => {
  return (
    <div className="join-topBlock ">
      {/* <div className="join-topBlock-b1">
        <img src="/join/b1-image.png" alt="" />
        <div>
          <div className="font-36 semi-bold t-color">{data.b1.title}</div>
          <div className="font-20 mt-24">
            {data.b1.description.map(d => (
              <div>{d}</div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="join-topBlock-b2 full-bg">
        {/* <Drop
          style={{ top: 0 }}
          color="#F0F9FE"
          bgColor="rgba(85,195,254)"
          size="l"
        /> */}
        <div className="font-36 semi-bold">{data.b2.title}</div>
        <div
          className="mt-48 font-20 block-middle"
          style={{ maxWidth: "648px" }}
        >
          {data.b2.content.map(c => (
            <div className="mt-24 quote">{c}</div>
          ))}
        </div>
      </div>
    </div>
  )
}
const Benefits = () => {
  return (
    <div className="join-benefits">
      <div className="join-benefits-container">
        <div className="font-36 semi-bold text-center t-color">
          {data.benefits.title}
        </div>
        <div className="join-benefits-itemContainerWrapper">
          <div className="join-benefits-itemContainer">
            {data.benefits.content.map(({ description, name, _key }) => (
              <div className="join-benefits-item">
                <img src={`/join/benefit-${_key}.png`} alt={name} />
                <div className="join-benefits-item-content mt-24">
                  <div className="font-28 semi-bold t-color title">{name}</div>
                  <div className="mt-24 description">{description}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="join-benefits-bottom  block-middle">
            <div className="join-benefits-bottom-content">
              <div className="imgContainer">
                <picture>
                  <source
                    srcSet="/join/benefit-dev-m.png 640w"
                    media="(max-width: 640px)"
                  />
                  <img
                    src="/join/benefit-dev.png"
                    alt={data.benefits.bottomTitle}
                  />
                </picture>
              </div>
              <div className="textContainer">
                <div className="font-28 semi-bold t-color">
                  {data.benefits.bottomTitle}
                </div>
                <div className="mt-24 font-16 description">
                  {data.benefits.bottomDescription}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const BottomBanner = () => {
  return (
    <div className="join-benefits-bottomBanner-wrap">
      <div className="join-benefits-bottomBanner">
        <div className="join-benefits-bottomBanner-bg-wrap">
          <div className="join-benefits-bottomBanner-bg full-bg cover-1920"></div>
        </div>
        <div style={{ overflow: "hidden" }}>
          <div className="join-benefits-bottomBanner-contentWrapper block-middle">
            <div className="join-benefits-bottomBanner-content">
              <div className="font-36 semi-bold t-color">{data.join.title}</div>
              <div className="mt-24">
                <Button
                  width={468}
                  size="l"
                  onClick={() => {
                    window.open(url.job.link)
                  }}
                >
                  {data.join.buttonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function Home() {
  return (
    <div className="join">
      <SEO {...data.seo} />
      <SolutionTopBanner
        {...data.topBanner}
        button={({ children }) => (
          <Button
            size="l"
            onClick={() => {
              window.open(url.job.link)
            }}
          >
            {children}
          </Button>
        )}
        bannerPic={{
          src: `/join/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        bgPic="/join/topBanner-bg.svg"
        bgColor="linear-gradient(360deg,rgba(194,234,255,1) 0%,rgba(239,250,255,1) 100%)"
        pic={
          lang === "zh"
            ? {
                src: "/join/topBanner-pic.png",
                style: { position: "absolute", bottom: 0, right: "52px" },
              }
            : undefined
        }
        extra={
          lang === "en" && (
            <video autoPlay={true} muted controls className="topBanner-video">
              <source src={`/2021-mid-year.mp4`} type="video/mp4" />
            </video>
          )
        }
      />
      <OurValue style={{ background: "#F0F9FE" }} />
      <TopBlock />
      <Benefits />
      <BottomBanner />
      <Footer />
    </div>
  )
}
